.home {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-left: auto;
  margin-right: auto;

  .contact-info {
    text-align: center;
    margin: 2rem 3rem;
    padding: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .home {
    width: 100%;

    .contact-info {
      text-align: center;
      margin: 0;
      padding: 0;
    }

    .projects {
      margin: 1rem;
      padding: 0.5rem;
    }

    .tech-stack {
      margin: 1rem 2rem;
      padding: 0rem;
    }
  }
}
