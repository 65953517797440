.footer {
  display: flex;
  justify-content: center;
  align-items: center;

  .footer-container {
    background-color: transparentize($color: $edgy-gold, $amount: 0.4);
    padding: 10px 0;
    border-radius: 0 0 10px 10px;
    width: 99%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    h5 {
      margin: 0.5rem 1rem;
      text-shadow: $x-offset $y-offset 0px $super-silver,
        calc($x-offset - $stroke) calc($y-offset + $stroke) 0px $timberwolf;
      color: $cinder;
      letter-spacing: 4px;
      span {
        font-family: $typing-font;
        letter-spacing: 2px;
      }
    }
    ul {
      max-width: 90%;
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;

      li {
        margin: 0 1rem;

        a {
          text-decoration: none;
          color: $super-silver;
          display: flex;
          justify-content: center;
          align-items: center;
          h5 {
            margin: 0;
            font-family: $typing-font;
            letter-spacing: 2px;
          }
          .footer-logo {
            margin-right: 8px;
            height: height;
            width: 25px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .footer {
    .footer-container {
      flex-direction: column;
      height: auto;
      ul {
        flex-direction: column;
      }
    }
  }
}
