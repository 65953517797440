.navbar {
  background-color: transparentize($edgy-gold, 0.8);
  padding: 1rem 0.5rem 0 0.5rem;
  border-radius: 5px 5px 0 0;

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: $cinder;
    font-family: $typing-font;
  }

  .navbar-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
  }
  .navbar-item {
    margin: 0.7rem;
  }
}

.uk-navbar-toggle{
  color:$font-color;
  &:hover{
    color:$background-dark;
  }
}
.uk-navbar-center{
  a{
    text-decoration: none;
  }
  h3{
    letter-spacing: 3px;
    padding: 0.8rem;
    margin: .2rem;
    font-size: 1.5rem;
    text-shadow: 2px 2px 0px $background-dark;
      &:hover{
      color:$light-independence;
      text-shadow: 0px 1px 0px $font-color;
    }
  }
}
.uk-nav{
  li>a{
    font-family: $handwriting-font;
    letter-spacing: 3px;
    padding: 0.8rem;
    margin: .2rem;
    font-size: 1.5rem;
    color:$font-color;
    text-shadow: 2px 2px 0px $background-dark;
  }
  li>a:hover{
    color:$light-independence;
    text-shadow: 0px 1px 0px $font-color;
  }
}

@media screen and (max-width:480px) {
  .navbar-container{
    .uk-navbar-nav{
      li>a{
        font-size:0.8rem;
        padding:0.5rem;
        margin:0rem;
      }
    }
  }
  
}