@import "../_variables/colors";
@import "../_variables/fonts";
@import "../pages/home";
@import "../pages/splash";
@import "../pages/project";
@import "../pages/photo";
@import "../components/Components/NavBar/nav";
@import "../components/Components/About/about";
@import "../components/Components/Contact/contact";
@import "../components/Components/TechStacks/stacks";
@import "../components/Components/Projects/projects";
@import "../components/Components/Footer/footer";
@import "../components/Components/PageContainer/pagecontainer";
@import "../components/Components/PhotoGallery/photogallery";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}
body {
  color: $font-color;
  font-family: $base-font;
  background: $super-silver;
  letter-spacing: 1px;
  margin: 15px 5px 1px 5px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font;
  color: $font-color;
  letter-spacing: 6px;
}

h1 {
  font-size: calc(1em + 3vmin);
  text-shadow: $x-offset $y-offset 0px $fern-green,
    calc($x-offset - $stroke) calc($y-offset + $stroke) 0px $edgy-gold;
}
