.about-container {
  margin: 2rem;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  .about-portrait {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 2s;
    -webkit-transition: 2s;
    -moz-transition: 2s;
    width: 30%;

    img {
      width: 300px;
      padding: 1rem;
      box-shadow: 2px 2px 0px 2px $timberwolf;
      overflow: hidden;
      background-color: $font-color;
      border-radius: 3px;
    }
  }
  .about-portrait:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
  }
  .about-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 60%;

    .text-box {
      background: transparentize($font-color, 0.7);
      box-shadow: 5px 5px 0px 3px $brunwick-green;
      border-radius: 5px;
      padding: 2rem;
      margin: 1rem;
      margin-top: 1.5rem;
    }
    h1 {
      text-align: center;
    }
    p {
      font-weight: 200;
      font-size: 23px;
    }
  }
}

@media screen and (max-width: 480px) {
  .about-container {
    margin: 1rem;
    padding-left: 0px;
    display: flex;
    flex-direction: column;

    .about-portrait {
      justify-self: center;
      width: 80%;
    }
    .about-portrait:hover {
      transform: scale(1.05);
      -webkit-transform: scale(1.05);
      -moz-transform: scale(1.05);
    }
    .about-text {
      width: 100%;
      margin: 1.5rem 0.5rem;
      h1 {
        text-align: center;
      }
      p {
        font-size: 15px;
      }
    }
  }
}
