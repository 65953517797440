@import url("https://fonts.googleapis.com/css2?family=Marcellus&family=Montserrat:wght@100;200;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shadows+Into+Light+Two&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Mono&display=swap");

$header-font: "Marcellus", serif;
$handwriting-font: "Shadows Into Light Two", cursive;
$base-font: "Montserrat", sans-serif;
$typing-font: "Space Mono", monospace;

$x-offset: -0.0625em;
$y-offset: 0.0625em;
$stroke: 0.025em;
