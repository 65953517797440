$font-color: #EFF3F6;
$background-color: #3C5769;
$light-background-color: #59819B;
$background-dark: #2B3D4A;
$hookers-green: #3C685C;
$light-hookers-green: #599B8A;
$deep-space-sparkle: #3C6063;
$light-deep-space: #5C9499;
$independence: #3E4A69;
$light-independence: #6577A4;
$cyber-grape: #3F3C68;
$light-cyber-grape: #6864A6;

//vesrsion 2 colors
$timberwolf: #DAD7CD;
$laurel-green: #A3B18A;
$fern-green: #588157;
$hunter-green: #3A5A40;
$brunwick-green: #344E41;

//version 3 colors
$hornblende: #362624;
$cinder: #202427;
$edgy-gold: #B29960;
$super-silver: #F1F1F1;